import { useState } from "react";
import { useAppContext } from "../../../../context/AppContext";
import { isMobile } from "react-device-detect";
import PlatinumCenter from "../platinum/center";
import PlatinumLeft from "../platinum/left";
import PlatinumRight from "../platinum/right";
import GoldCenter from "../gold/center";
import GoldLeft from "../gold/left";
import GoldRight from "../gold/right";
import BronzeLeft from "../bronze/left";
import BronzeLeftCenter from "../bronze/leftCenter";
import BronzeRight from "../bronze/right";
import BronzeRightCenter from "../bronze/rightCenter";
// import LeftTop from "../left/top";
// import LeftCenter from "../left/center";
// import LeftBottom from "../left/bottom";
// import RightTop from "../right/top";
// import RightCenter from "../right/center";
// import RightBottom from "../right/bottom";

import styles from "./index.module.css";

const CaroleSeatMap = ({ eventCard }) => {
  const { setModal } = useAppContext();
  const [maxWidth, setMaxWidth] = useState(isMobile ? 400 : 700);
  const zoomOut = () => {
    if (maxWidth < 1000) {
      setMaxWidth(maxWidth + 30);
    }
  };
  const zoomIn = () => {
    if (maxWidth > 400) {
      setMaxWidth(maxWidth - 30);
    }
  };

  return (
    <div className="container">
      <div className={styles.zoom_out}>
        <div onClick={() => zoomOut()} className={styles.sit_contain}>
          <img
            className={styles.pieceMap}
            src="/img/map/zoom_out.svg"
            alt="zoom_out"
          />
        </div>
      </div>
      <div className={styles.zoom_in}>
        <div onClick={() => zoomIn()} className={styles.sit_contain}>
          <img
            className={styles.pieceMap}
            src="/img/map/zoom_in.svg"
            alt="zoom_in"
          />
        </div>
      </div>
      <p className="seating__area_txt" style={{ width: maxWidth }}>
        1. Please select the seating area first
      </p>
      <div className="pops__map_container" style={{ width: maxWidth }}>
        <div className={styles.stage}>
          <div className={styles.sit_contain}>
            <img
              className={styles.stage_img}
              src="/img/map/Carole/stage.svg"
              alt="stage"
            />
          </div>
        </div>
        <div className={styles.orchestra_center}>
          <div className={styles.sit_contain}>
            <div
              onClick={() =>
                eventCard.id === "364f9021-f60c-45d6-8629-2056227dbbfb" &&
                setModal({
                  open: true,
                  children: <PlatinumCenter eventCard={eventCard} />,
                })
              }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src={
                  eventCard.id === "364f9021-f60c-45d6-8629-2056227dbbfb"
                    ? "/img/map/Carole/platinum_center_active.svg"
                    : "/img/map/Carole/platinum_center.svg"
                }
                alt="orchestra_center"
              />
            </div>
          </div>
        </div>
        <div className={styles.orchestra_left}>
          <div className={styles.sit_contain}>
            <div
              onClick={() =>
                eventCard.id === "364f9021-f60c-45d6-8629-2056227dbbfb" &&
                setModal({
                  open: true,
                  children: <PlatinumLeft eventCard={eventCard} />,
                })
              }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src={
                  eventCard.id === "364f9021-f60c-45d6-8629-2056227dbbfb"
                    ? "/img/map/Carole/platinum_left_active.svg"
                    : "/img/map/Carole/platinum_left.svg"
                }
                alt="orchestra_left"
              />
            </div>
          </div>
        </div>
        <div className={styles.orchestra_right}>
          <div className={styles.sit_contain}>
            <div
              onClick={() =>
                eventCard.id === "364f9021-f60c-45d6-8629-2056227dbbfb" &&
                setModal({
                  open: true,
                  children: <PlatinumRight eventCard={eventCard} />,
                })
              }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src={
                  eventCard.id === "364f9021-f60c-45d6-8629-2056227dbbfb"
                    ? "/img/map/Carole/platinum_right_active.svg"
                    : "/img/map/Carole/platinum_right.svg"
                }
                alt="orchestra_right"
              />
            </div>
          </div>
        </div>
        <div className={styles.gold_center}>
          <div className={styles.sit_contain}>
            <div
              onClick={() =>
                eventCard.id === "87c56fad-895b-47ad-bb17-5d5d4ca00725" &&
                setModal({
                  open: true,
                  children: <GoldCenter eventCard={eventCard} />,
                })
              }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src={
                  eventCard.id === "87c56fad-895b-47ad-bb17-5d5d4ca00725"
                    ? "/img/map/Carole/gold_center_active.svg"
                    : "/img/map/Carole/gold_center.svg"
                }
                alt="gold_center"
              />
            </div>
          </div>
        </div>
        <div className={styles.gold_left}>
          <div className={styles.sit_contain}>
            <div
              onClick={() =>
                eventCard.id === "87c56fad-895b-47ad-bb17-5d5d4ca00725" &&
                setModal({
                  open: true,
                  children: <GoldLeft eventCard={eventCard} />,
                })
              }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src={
                  eventCard.id === "87c56fad-895b-47ad-bb17-5d5d4ca00725"
                    ? "/img/map/Carole/gold_left_active.svg"
                    : "/img/map/Carole/gold_left.svg"
                }
                alt="gold_left"
              />
            </div>
          </div>
        </div>
        <div className={styles.gold_right}>
          <div className={styles.sit_contain}>
            <div
              onClick={() =>
                eventCard.id === "87c56fad-895b-47ad-bb17-5d5d4ca00725" &&
                setModal({
                  open: true,
                  children: <GoldRight eventCard={eventCard} />,
                })
              }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src={
                  eventCard.id === "87c56fad-895b-47ad-bb17-5d5d4ca00725"
                    ? "/img/map/Carole/gold_right_active.svg"
                    : "/img/map/Carole/gold_right.svg"
                }
                alt="gold_right"
              />
            </div>
          </div>
        </div>
        <div className={styles.left_top}>
          <div className={styles.sit_contain}>
            <div
              // onClick={() =>
              //   setModal({
              //     open: true,
              //     children: <LeftTop eventCard={eventCard} />,
              //   })
              // }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src="/img/map/Carole/gallery_1.svg"
                alt="left_top"
              />
            </div>
          </div>
        </div>
        <div className={styles.left_middle}>
          <div className={styles.sit_contain}>
            <div
              // onClick={() =>
              //   setModal({
              //     open: true,
              //     children: <LeftCenter eventCard={eventCard} />,
              //   })
              // }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src="/img/map/Carole/gallery_2.svg"
                alt="left_middle"
              />
            </div>
          </div>
        </div>
        <div className={styles.left_bottom}>
          <div className={styles.sit_contain}>
            <div
              // onClick={() =>
              //   setModal({
              //     open: true,
              //     children: <LeftBottom eventCard={eventCard} />,
              //   })
              // }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src="/img/map/Carole/gallery_3.svg"
                alt="left_bottom"
              />
            </div>
          </div>
        </div>
        <div className={styles.right_top}>
          <div className={styles.sit_contain}>
            <div
              // onClick={() =>
              //   setModal({
              //     open: true,
              //     children: <RightTop eventCard={eventCard} />,
              //   })
              // }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src="/img/map/Carole/gallery_1.svg"
                alt="right_top"
              />
            </div>
          </div>
        </div>
        <div className={styles.right_middle}>
          <div className={styles.sit_contain}>
            <div
              // onClick={() =>
              //   setModal({
              //     open: true,
              //     children: <RightCenter eventCard={eventCard} />,
              //   })
              // }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src="/img/map/Carole/gallery_2.svg"
                alt="right_middle"
              />
            </div>
          </div>
        </div>
        <div className={styles.right_bottom}>
          <div className={styles.sit_contain}>
            <div
              // onClick={() =>
              //   setModal({
              //     open: true,
              //     children: <RightBottom eventCard={eventCard} />,
              //   })
              // }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src="/img/map/Carole/gallery_3.svg"
                alt="right_bottom"
              />
            </div>
          </div>
        </div>
        <div className={styles.rear_1}>
          <div className={styles.sit_contain}>
            <div
              onClick={() =>
                eventCard.id === "02333bab-7c5d-4b28-9898-b4009d84cc22" &&
                setModal({
                  open: true,
                  children: <BronzeLeftCenter eventCard={eventCard} />,
                })
              }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src={
                  eventCard.id === "02333bab-7c5d-4b28-9898-b4009d84cc22"
                    ? "/img/map/Carole/rear_1_active.svg"
                    : "/img/map/Carole/rear_1.svg"
                }
                alt="rear_1"
              />
            </div>
          </div>
        </div>
        <div className={styles.rear_2}>
          <div className={styles.sit_contain}>
            <div
              onClick={() =>
                eventCard.id === "02333bab-7c5d-4b28-9898-b4009d84cc22" &&
                setModal({
                  open: true,
                  children: <BronzeRightCenter eventCard={eventCard} />,
                })
              }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src={
                  eventCard.id === "02333bab-7c5d-4b28-9898-b4009d84cc22"
                    ? "/img/map/Carole/rear_1_active.svg"
                    : "/img/map/Carole/rear_1.svg"
                }
                alt="rear_2"
              />
            </div>
          </div>
        </div>
        <div className={styles.rear_3}>
          <div className={styles.sit_contain}>
            <div
              onClick={() =>
                eventCard.id === "02333bab-7c5d-4b28-9898-b4009d84cc22" &&
                setModal({
                  open: true,
                  children: <BronzeLeft eventCard={eventCard} />,
                })
              }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src={
                  eventCard.id === "02333bab-7c5d-4b28-9898-b4009d84cc22"
                    ? "/img/map/Carole/rear_2_active.svg"
                    : "/img/map/Carole/rear_2.svg"
                }
                alt="rear_3"
              />
            </div>
          </div>
        </div>
        <div className={styles.rear_4}>
          <div className={styles.sit_contain}>
            <div
              onClick={() =>
                eventCard.id === "02333bab-7c5d-4b28-9898-b4009d84cc22" &&
                setModal({
                  open: true,
                  children: <BronzeRight eventCard={eventCard} />,
                })
              }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src={
                  eventCard.id === "02333bab-7c5d-4b28-9898-b4009d84cc22"
                    ? "/img/map/Carole/rear_2_active.svg"
                    : "/img/map/Carole/rear_2.svg"
                }
                alt="rear_4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaroleSeatMap;
