import React, { useEffect, useRef, useState, useMemo } from "react";
import { useMediaQuery } from "usehooks-ts";
import OwlCarousel from "react-owl-carousel";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Web3 from "web3";

import TicketBuyModal from "../../../components/custom_modals/ticket_buy_modal";
import CashModal from "../../../components/custom_modals/cash_modal";
import FreeModal from "../../../components/custom_modals/free_modal";
import ForceModal from "../../../components/custom_modals/force_modal";
import PaymentSuccessModal from "../../../components/custom_modals/payment_success_modal";
import SelfieSuccessModal from "../../../components/custom_modals/selfie_success_modal";
import PaymentFailedModal from "../../../components/custom_modals/payment_failed_modal";
import FloorMapModal from "../../../components/custom_modals/floor_map_modal";
// import SelectDateModal from "../../../components/custom_modals/select_date_modal";
import PermissionModal from "../../../components/custom_modals/permission_modal";
import CarouselButton from "../../../components/carousel_button";
import { useAppContext } from "../../../context/AppContext";
import EventCardInfo from "../../../components/event_card_info";
import Tile from "../../../components/tile";

import {
  getEventCardById,
  getLatestEventCards,
  updateEventLike,
  increaseViews,
} from "../../../api/event";
import { stripePaymentConfirm } from "../../../api/credit";
import { saleStopEventCard, findUserTickets } from "../../../api/event";
import { IEventCard } from "../../../utils/interface";
// import useAnalyticsEventTracker from "../../../utils/ga";
import { getPermissionByEmail } from "../../../api/permission";
import { createShareLinkTrack } from "../../../api/sharelinktrack";
// import * as nearAPI from "near-api-js";
import { useUserContext } from "../../../context/UserContext";

// import { getEventPrice } from "../../../utils";

// import DomingoSeatMap from "../../../components/custom_modals/domingo_map/seat_board";
// import CongressSeatMap from "../../../components/custom_modals/congress_map/seat_board";
// import AhoySeatMap from "../../../components/custom_modals/ahoy_map/seat_board";
// import ChicagoSeatMap from "../../../components/custom_modals/chicago_map/seat_board";
// import RitzSeatMap from "../../../components/custom_modals/ritz_map/seat_board";
// import MiamiSeatMap from "../../../components/custom_modals/miami_map/seat_board";
// import RickySeatMap from "../../../components/custom_modals/ricky_map/seat_board";
// import EnriqueSeatMap from "../../../components/custom_modals/enrique_map/seat_board";
// import Ritz7thSeatMap from "../../../components/custom_modals/ritz_7th_map/seat_board";
// import CivicSeatMap from "../../../components/custom_modals/civic_map/seat_board";
// import AureneSeatMap from "../../../components/custom_modals/aurene_map/seat_board";
// import PopsSeatMap from "../../../components/custom_modals/pops_map/seat_board";
import CaroleSeatMap from "../../../components/custom_modals/carole_map/seat_board";
import Carole8thSeatMap from "../../../components/custom_modals/carole_8th_map/seat_board";
// import Pops6thSeatMap from "../../../components/custom_modals/pops_map_6th/seat_board";
// import Pops7thSeatMap from "../../../components/custom_modals/pops_map_7th/seat_board";
import client from "../../../utils/ipfs_client";
import { permissionFilter } from "../../../utils/permission";
import CustomHelmet from "../../../components/CustomHelmet";
import styles from "./index.module.css";

const PageEventCard = () => {
  let { eventId } = useParams();
  const location = useLocation();
  const id = useMemo(() => {
    if (location.pathname.toLowerCase() === "/ibizafullexperience") {
      return "50165002-91f5-40a4-bd9f-ebd9928b5ef5";
    } else if (location.pathname.toLowerCase() === "/spacetaleofus") {
      return "ccf8f7c0-cd8d-4e3c-8cd0-05782c4f92e1";
    } else {
      return eventId;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);
  const {
    setModal,
    loading,
    setLoading,
    carouselItem,
    addToast,
    paymentModal,
    setPaymentModal,
    selectedSeats,
    setSelectedSeats,
    permission,
    ticketAmount,
    setTicketAmount,
    setDiscount,
  } = useAppContext();
  const { userInfo } = useUserContext();
  const [eventCard, setEventCard] = useState<any>();
  const [latestEvents, setLatestEvents] = useState<IEventCard[]>([]);
  const latestEventCarousel = useRef<typeof OwlCarousel>(null);
  const container = useRef<any>(null);
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSold, setSold] = useState(false);
  const [details, setDetails] = useState(true);
  // const gaEventTracker = useAnalyticsEventTracker("Buy Ticket");
  const [searchParams] = useSearchParams();
  const isTablet = useMediaQuery("(max-width: 550px)");
  const isMobile = useMediaQuery("(max-width: 450px)");

  const [updateScreen, setUpdate] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setUpdate(!updateScreen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestEvents]);

  useEffect(() => {
    setLoading(true);
    getEventCardData();
    if (id) {
      setIsLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    getLatestEventCards().then((res) => {
      if (res.success) {
        setLatestEvents(res.eventcards);
      }
    });
  }, [id]);

  useEffect(() => {
    const terms = searchParams.get("termsConditions");
    if (terms) {
      setDetails(false);
      navigate(`/event/eventcard/${id}`);
    }
    const payment_intent = searchParams.get("payment_intent");
    const payment_intent_client_secret = searchParams.get(
      "payment_intent_client_secret"
    );
    if (payment_intent && payment_intent_client_secret) {
      stripeSuccessHandle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (paymentModal && userInfo.user.user_type === "CASHIER ADMIN") {
      setPaymentModal(false);
      setModal({
        open: true,
        children: (
          <CashModal
            amount={selectedSeats.length}
            eventCard={eventCard}
            handleTicket={handleBuyTicket}
          />
        ),
      });
    } else if (paymentModal && userInfo.user.user_type === "FREE ADMIN") {
      setPaymentModal(false);
      setModal({
        open: true,
        children: (
          <FreeModal
            amount={selectedSeats.length}
            eventCard={eventCard}
            handleTicket={handleBuyTicket}
          />
        ),
      });
    } else if (paymentModal && userInfo.user.user_type === "FORCE ADMIN") {
      setPaymentModal(false);
      setModal({
        open: true,
        children: (
          <ForceModal
            amount={selectedSeats.length}
            eventCard={eventCard}
            handleTicket={handleBuyTicket}
          />
        ),
      });
    } else if (paymentModal) {
      setPaymentModal(false);
      showTicketModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentModal]);

  useEffect(() => {
    setSelectedSeats([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setTicketAmount(1);
    setDiscount(0);
    createLinkTrack();
    increaseViews({ id, media: searchParams.get("media") || "none" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stripeSuccessHandle = async () => {
    if (!loading) {
      setLoading(true);
    }
    const payment_intent = searchParams.get("payment_intent");
    const token = localStorage.getItem("stripe_token");
    const payment_intent_client_secret = searchParams.get(
      "payment_intent_client_secret"
    );
    const NFT_IPFS = await uploadIPFS();
    console.log(NFT_IPFS);
    const nftData = {
      contract: JSON.parse(localStorage.getItem("ccObject") as string)
        ?.contractAddress,
      IPFS_URL: NFT_IPFS,
      account: JSON.parse(localStorage.getItem("ccObject") as string)
        ?.userWallet,
      picture_ipfs: JSON.parse(localStorage.getItem("ccObject") as string)
        ?.picture_ipfs,
    };
    stripePaymentConfirm({
      payment_intent,
      payment_intent_client_secret,
      token,
      data: JSON.parse(localStorage.getItem("ccObject") as string),
      nftData,
    })
      .then((res) => {
        getEventCardData();
        localStorage.removeItem("ccObject");
        if (res.success) {
          if (id === "e787b769-4fab-44f9-bdf1-3b793f1649d8") {
            setModal({
              open: true,
              children: <SelfieSuccessModal />,
            });
          } else {
            setModal({
              open: true,
              children: <PaymentSuccessModal />,
            });
          }
        } else {
          setModal({
            open: true,
            children: <PaymentFailedModal />,
          });
        }
        navigate(`/event/eventcard/${id}`);
        setLoading(false);
      })
      .catch((error) => {
        addToast(
          "Payment failed, contact card issuer or try with a different card.",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
        localStorage.removeItem("ccObject");
        navigate(`/event/eventcard/${id}`);
        console.log(error);
        setLoading(false);
      });
  };

  const uploadIPFS = async () => {
    setLoading(true);
    const added = await client.add(
      JSON.stringify(
        JSON.parse(localStorage.getItem("ccObject") as string)?.tokenObject
      )
    );
    const ipfs_url = `https://bkstage.infura-ipfs.io/ipfs/${added.path}`;
    return ipfs_url;
  };

  const getEventCardData = async () => {
    await getEventCardById(id).then((res) => {
      console.log(res, id);
      if (res.success) {
        if (res.eventcard.deleted === 1) {
          navigate("/");
        } else {
          setEventCard(res.eventcard);
          fixedTime(res.eventcard.date);

          if (res.eventcard.total_tickets === res.eventcard.buy_count) {
            setSold(true);
          } else {
            setSold(false);
          }
        }
      } else {
        navigate("/");
      }
      if (!searchParams.get("payment_intent")) {
        setLoading(false);
      }
    });
  };

  const _onClickLike = () => {
    if (!userInfo) {
      const url = `/event/eventcard/${id}`;
      navigate(`/signin?redirectURL=${url}`);
      return;
    }
    let likes: any[] = [];
    try {
      likes = JSON.parse((eventCard as IEventCard).likes_number);
    } catch (err) {
      likes = [];
    }
    if (typeof likes !== "object" || likes === null) likes = [];
    const userId = userInfo.user.id;
    if (likes.includes(userId)) {
      const index = likes.indexOf(userId);
      likes.splice(index, 1);
    } else {
      likes.push(userId);
    }
    updateEventLike({
      id: (eventCard as IEventCard).id,
      likes_number: JSON.stringify(likes),
    }).then((res) => {
      if (res.success) {
        setEventCard({ ...eventCard, likes_number: JSON.stringify(likes) });
      }
    });
  };

  const saleStop = async () => {
    setLoading(true);
    const res = await saleStopEventCard({
      id: eventCard.id,
    });
    console.log(res);
    setEventCard(res.event);
    setLoading(false);
  };

  const next = (eleRf: any) => {
    const ele: any = eleRf.current;
    ele.next(500);
  };

  const prev = (eleRf: any) => {
    const ele: any = eleRf.current;
    ele.prev(500);
  };

  const handleBuyTicket = () => {
    const currentTime = new Date();
    const timeZoneDiff =
      (currentTime.getTimezoneOffset() / 60 + eventCard.time_zone) *
      3600 *
      1000;

    if (
      eventCard.stop_time !== null &&
      currentTime.getTime() + timeZoneDiff >
        new Date(eventCard.stop_time).getTime()
    ) {
      addToast(
        "You can purchase the event ticket up to 2 hours before the event starts.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
      return;
    }

    if (isNaN(Number(ticketAmount)) || Number(ticketAmount) <= 0) {
      return;
    }

    if (userInfo) {
      if (eventCard.total_tickets <= eventCard.buy_count) {
        addToast("Already sold full amount of tickets", {
          appearance: "error",
          autoDismiss: true,
        });
        return;
      } else if (
        eventCard.buy_count + Number(ticketAmount) >
        eventCard.total_tickets
      ) {
        addToast(
          `Only ${eventCard.total_tickets -
            eventCard.buy_count} tickets are left`,
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
        return;
      }
      showPaymentModal(true);

      return;
    } else {
      const url = `/event/eventcard/${id}`;
      navigate(`/signin?redirectURL=${url}`);
    }
  };

  const checkPurchase = async () => {
    setLoading(true);
    const res = await findUserTickets({
      userId: userInfo.user.id,
      eventCardId: eventCard.id,
    });
    setLoading(false);
    return res.ticket.length;
  };

  const showTicketModal = async () => {
    if (eventCard.id === process.env.REACT_APP_LARS_EVENT) {
      const permission = await getPermissionByEmail({
        email: userInfo.user.email,
        eventcard_id: eventCard.id,
      });
      if (permission) {
        addToast(
          "You already sent your request to the admin. Please choose the approved ticket again.",
          {
            appearance: "info",
            autoDismiss: true,
          }
        );
        navigate(`/collection/${eventCard.collection.id}`);
      } else {
        setModal({
          open: true,
          children: <PermissionModal eventCard={eventCard} />,
        });
      }
    } else if (
      eventCard.collection.id === process.env.REACT_APP_LARS_COLLECTION
    ) {
      if (permissionFilter(eventCard, permission)) {
        const oldtickets = await checkPurchase();
        if (oldtickets + ticketAmount > permission.ticket_amount) {
          addToast(
            "You already bought this ticket or are going to buy more tickets than you ordered.",
            {
              appearance: "info",
              autoDismiss: true,
            }
          );
        } else {
          setModal({
            open: true,
            children: <TicketBuyModal eventCard={eventCard} />,
          });
        }
      } else {
        navigate("/");
      }
    } else {
      setModal({
        open: true,
        children: <TicketBuyModal eventCard={eventCard} />,
      });
    }
  };

  const showPaymentModal = async (value: boolean) => {
    setLoading(true);
    const web3 = new Web3("https://bsc-dataseed.binance.org/");
    const balanceAmount = await web3.eth.getBalance(
      process.env.REACT_APP_MINT_WALLET as string
    );
    setLoading(false);
    const trx = Number(BigInt(balanceAmount)) / 1000000000000000000;
    if (trx < 0.001) {
      addToast("Sorry, try again in 5 minutes.", {
        appearance: "warning",
        autoDismiss: true,
      });
      return;
    }
    if (eventCard.NFT_address === null) {
      addToast("Sorry, you can buy it after setting is finished.", {
        appearance: "warning",
        autoDismiss: true,
      });
      return;
    }
    if (eventCard.collection.id === "8582448a-4e47-4eb5-be87-7544b33d1903") {
      setModal({
        open: true,
        children: <CaroleSeatMap eventCard={eventCard} />,
      });
    } else if (
      eventCard.collection.id === "b06d2aca-37b2-44f1-bf46-8e46d37fbb08"
    ) {
      setModal({
        open: true,
        children: <Carole8thSeatMap eventCard={eventCard} />,
      });
    } else {
      if (value) {
        if (userInfo.user.user_type === "CASHIER ADMIN") {
          setModal({
            open: true,
            children: (
              <CashModal
                amount={Number(ticketAmount)}
                eventCard={eventCard}
                handleTicket={handleBuyTicket}
              />
            ),
          });
        } else if (userInfo.user.user_type === "FREE ADMIN") {
          setModal({
            open: true,
            children: (
              <FreeModal
                amount={Number(ticketAmount)}
                eventCard={eventCard}
                handleTicket={handleBuyTicket}
              />
            ),
          });
        } else if (userInfo.user.user_type === "FORCE ADMIN") {
          setModal({
            open: true,
            children: (
              <ForceModal
                amount={Number(ticketAmount)}
                eventCard={eventCard}
                handleTicket={handleBuyTicket}
              />
            ),
          });
        } else {
          showTicketModal();
        }
      } else {
        setModal({ open: false });
      }
    }
  };

  const fixedTime = (date: any) => {
    const dd = moment(date).format("h:mm a");
    return dd;
  };

  const showFloorMap = () => {
    setModal({
      open: true,
      children: <FloorMapModal eventCard={eventCard} />,
    });
  };

  const goBack = () => {
    navigate(-1);
  };

  const createLinkTrack = async () => {
    const trackId = searchParams.get("trackId");
    const media = searchParams.get("media");
    if (trackId) {
      console.log(trackId);
      const data = {
        trackId,
        media,
        url: window.location.href,
        category: "eventcard",
      };
      const res = await createShareLinkTrack(data);
      console.log(res);
    }
  };

  return (
    <div className={styles.item__detail_container}>
      {eventCard && (
        <CustomHelmet
          title={eventCard.name}
          description={
            eventCard.description
              ? eventCard.description
              : eventCard.venue_description
          }
          keywords={eventCard.name}
          image={`${process.env.REACT_APP_ASSET_BASE_URL}/${eventCard.picture_large}`}
          canonical={window.location.href}
        />
      )}
      <div ref={container}>
        {isTablet ? (
          <div className="article__top">
            <div onClick={goBack}>
              <LazyLoadImage src="/img/icons/arrow-left.svg" alt="data" />
            </div>
          </div>
        ) : (
          <div className="row row--grid">
            <div className="col-12" style={{ padding: 0 }}>
              <ul className="breadcrumb">
                <li className="breadcrumb__item">
                  <Link to="/">{t("home")}</Link>
                </li>
                <li className="breadcrumb__item">{eventCard?.creator?.name}</li>
                <li className="breadcrumb__item breadcrumb__item--active">
                  {t("item")}
                </li>
              </ul>
            </div>
          </div>
        )}

        {eventCard && (
          <EventCardInfo
            eventCard={eventCard}
            onClickLike={() => _onClickLike()}
            showFloorMap={() => showFloorMap()}
            isSold={isSold}
            handleBuyTicket={() => handleBuyTicket()}
            ticketAmount={ticketAmount}
            setTicketAmount={setTicketAmount}
            fixedTime={fixedTime}
            saleStop={saleStop}
          />
        )}

        {eventCard && (
          <div className="description__contain">
            <div className="details__terms">
              <p
                className={
                  !details ? "description__title" : "description__title_active"
                }
                onClick={() => setDetails(true)}
              >
                {t("details")}
              </p>
              <p
                className={
                  details ? "description__title" : "description__title_active"
                }
                onClick={() => setDetails(false)}
              >
                {t("terms and conditions")}
              </p>
            </div>
            <div
              className="description__text"
              dangerouslySetInnerHTML={{
                __html: details
                  ? eventCard?.description
                    ? eventCard?.description
                    : eventCard?.venue_description
                  : eventCard?.terms_conditions,
              }}
            />
          </div>
        )}
      </div>
      {!isMobile && (
        <section className="row row--grid">
          <div className="col-12 p__zero">
            <div className="main__title main__title--border-top">
              <h2>
                <Link to="/explorer" style={{ fontWeight: 700 }}>
                  {t("other author assets")}
                </Link>
              </h2>
            </div>
          </div>

          {latestEvents.length > 0 && (
            <div style={{ width: "100%", overflow: "hidden" }}>
              <div
                className="carousel-wrapper"
                style={{ position: "relative" }}
              >
                <CarouselButton
                  prev={() => prev(latestEventCarousel)}
                  next={() => next(latestEventCarousel)}
                />
                {isLoading && (
                  <OwlCarousel
                    className="owl-theme"
                    margin={12}
                    items={carouselItem}
                    autoplay={true}
                    autoplayHoverPause={true}
                    loop={
                      latestEvents.length < 4 ? (isTablet ? true : false) : true
                    }
                    dots={false}
                    ref={latestEventCarousel as any}
                  >
                    {latestEvents.map(
                      (eventcard: any, i) =>
                        permissionFilter(eventcard, permission) && (
                          <Tile
                            key={`explorer_event_${i}`}
                            eventCard={eventcard}
                            i={i}
                            deleteEventCard={() => {}}
                          />
                        )
                    )}
                  </OwlCarousel>
                )}
              </div>
            </div>
          )}
        </section>
      )}
    </div>
  );
};

export default PageEventCard;
