import { useState, useEffect, useRef } from "react";
import { useAppContext } from "../../../../context/AppContext";
import HoustonSeatMap from "../seat_board";
import { getEventCardById } from "../../../../api/event";
import Fiat from "../../../Fiat";
import FiatSymbol from "../../../FiatSymbol";
import { getEventPrice } from "../../../../utils";

const PlatinumLeft = ({ eventCard }) => {
  const bp_seats = [
    [0, 0, 0, 0, 0, 17, 15, 13, 11, 9, 7, 5, 3, 1],
    [0, 0, 0, 0, 0, 0, 0, 13, 11, 9, 7, 5, 3, 1],
    [0, 0, 0, 0, 0, 0, 15, 13, 11, 9, 7, 5, 0, 0],
    [0, 0, 0, 0, 0, 17, 15, 13, 11, 9, 7, 5, 3, 1],
    [0, 0, 0, 0, 19, 17, 15, 13, 11, 9, 7, 5, 3, 1],
    [0, 0, 0, 21, 19, 17, 15, 13, 11, 9, 7, 5, 0, 0],
    [0, 0, 23, 21, 19, 17, 15, 13, 11, 9, 7, 5, 3, 1],
    [0, 0, 23, 21, 19, 17, 15, 13, 11, 9, 7, 5, 3, 1],
    [27, 25, 23, 21, 19, 17, 15, 13, 11, 9, 7, 5, 3, 1],
    [27, 25, 23, 21, 19, 17, 15, 13, 11, 9, 7, 5, 3, 1],
    [27, 25, 23, 21, 19, 17, 15, 13, 11, 9, 7, 5, 3, 1],
    [27, 25, 23, 21, 19, 17, 15, 13, 11, 9, 7, 5, 3, 1],
    [0, 0, 23, 21, 19, 17, 15, 13, 11, 9, 7, 5, 3, 1],
    [0, 0, 0, 21, 19, 17, 15, 13, 11, 9, 7, 5, 0, 0],
    [0, 0, 0, 21, 19, 17, 15, 13, 11, 9, 7, 5, 3, 1],
  ];
  const eng_row = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
  ];
  const {
    setModal,
    setPaymentModal,
    selectedSeats,
    setSelectedSeats,
    addToast,
    setLoading,
  } = useAppContext();
  const [seats, setSeats] = useState(bp_seats);
  const [event, setEvent] = useState(eventCard);
  const selectedRef = useRef(null);
  selectedRef.current = selectedSeats;

  const selectSeat = (x, col) => {
    let _seats = [...seats];
    for (let index = 0; index < _seats[x - 1].length; index++) {
      const element = _seats[x - 1][index];
      if (element === col) {
        _seats[x - 1][index] = col * -1;
      }
    }
    setSeats(_seats);
    setSelectedSeats([
      ...selectedSeats,
      { x: eng_row[x - 1], y: col, seat_block: "Section Platinum" },
    ]);
  };

  const unSelectSeat = (x, col) => {
    let _seats = [...seats];
    for (let index = 0; index < _seats[x - 1].length; index++) {
      const element = _seats[x - 1][index];
      if (element === col) {
        _seats[x - 1][index] = col * -1;
      }
    }
    setSeats(_seats);

    let s = [...selectedSeats];
    let index = s.findIndex(
      (item) => item.x === eng_row[x - 1] && item.y === col * -1
    );

    if (index !== -1) {
      // Remove the item from the array using splice
      s.splice(index, 1);
      setSelectedSeats([...s]);
    }
  };

  const next = () => {
    console.log(selectedSeats);
    if (selectedSeats.length > 0) {
      setPaymentModal(true);
    }
  };

  const back = () => {
    setModal({
      open: true,
      children: <HoustonSeatMap eventCard={event} />,
    });
  };

  useEffect(() => {
    setSelectedSeats([]);
    setLoading(true);
    getEventCardById(eventCard.id).then((res) => {
      let nonSeats = JSON.parse(res.eventcard.seats);
      let _seats = [...seats];
      for (let index = 0; index < nonSeats.length; index++) {
        const element = nonSeats[index];
        if (element.seat_block === "Section Platinum") {
          const selected_row = eng_row.indexOf(element.x);
          if (selected_row !== -1) {
            for (let index = 0; index < _seats[selected_row].length; index++) {
              const el = _seats[selected_row][index];
              if (el === element.y) {
                _seats[selected_row][index] = "x";
              }
            }
          }
        }
      }
      setSeats(_seats);
      setLoading(false);
    });
    const interval = setInterval(() => {
      getEventCardById(eventCard.id).then((res) => {
        setEvent(res.eventcard);
        if (res.eventcard.seats !== "") {
          let eventSeats = JSON.parse(res.eventcard.seats);
          let original = seats;

          if (
            eventSeats.some((item1) =>
              selectedRef.current.some(
                (item2) =>
                  item1.x === item2.x &&
                  item1.y === item2.y &&
                  item1.seat_block === item2.seat_block
              )
            )
          ) {
            console.log(selectedRef.current, eventSeats);
            for (let index = 0; index < selectedRef.current.length; index++) {
              const element = selectedRef.current[index];
              const selected_row = eng_row.indexOf(element.x);
              if (selected_row !== -1) {
                for (let i = 0; i < original[selected_row].length; i++) {
                  if (original[selected_row][i] === element.y * -1) {
                    original[selected_row][i] = element.y;
                  }
                }
              }
            }
            addToast(
              "Please choose your seats again because the seat you selected is just sold.",
              {
                appearance: "warning",
                autoDismiss: true,
              }
            );
            setSelectedSeats([]);
          }
          for (let index = 0; index < eventSeats.length; index++) {
            const element = eventSeats[index];
            if (element.seat_block === "Section Platinum") {
              const selected_row = eng_row.indexOf(element.x);
              if (selected_row !== -1) {
                for (
                  let index = 0;
                  index < original[selected_row].length;
                  index++
                ) {
                  const el = original[selected_row][index];
                  if (el === element.y) {
                    original[selected_row][index] = "x";
                  }
                }
              }
            }
          }
          setSeats([...original]);
        }
      });
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="select__seat">
      <div className="seat_select__top">
        <div className="seat_select__back" onClick={() => back()}>
          <svg
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 13L1 7L7 1"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="back_text">BACK</span>
        </div>
        <p className="select__seats_txt">2. Select the seat(s)</p>
        <div className="btn_close" onClick={() => setModal({ open: false })}>
          <svg
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              fill="#ffffff"
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            ></path>
          </svg>
        </div>
      </div>
      <div className="seat__map_container">
        {seats.map((row, x) => (
          <div key={x} style={{ display: "flex" }}>
            {row.map((col, y) =>
              col > 0 ? (
                <div key={y} className="seat">
                  <div
                    onClick={() => selectSeat(1 + x, col)}
                    className="seat__number"
                  >
                    {col}
                  </div>
                </div>
              ) : col < 0 ? (
                <div key={y} className="seat">
                  <div
                    onClick={() => unSelectSeat(1 + x, col)}
                    className="seat__selected_number"
                  >
                    {Math.abs(col)}
                  </div>
                </div>
              ) : col === "x" ? (
                <div key={y} className="seat">
                  <div className="non__seat_close">{col}</div>
                </div>
              ) : (
                <div key={y} className="blank__seat"></div>
              )
            )}
            <div className="seat__row_number">{eng_row[x]}</div>
          </div>
        ))}
      </div>
      <div className="seat_select__bottom">
        <p className="area__text">Area Section Platinum</p>
        <div>
          <div className="tax__block">
            <p className="ticket__total_price">
              <span
                className="text__small-time text__small-tax "
                style={{ marginRight: 10 }}
              >
                {eventCard.tax_include ? "INCL. TAX" : "EXCL. TAX"}
              </span>
              <Fiat
                price={
                  getEventPrice(eventCard) *
                  (selectedSeats.length > 0 ? selectedSeats.length : 1)
                }
                currency={eventCard.currency}
              />
              &nbsp;
              <FiatSymbol />
            </p>
          </div>
          <div className="seats__buy_container">
            <button className="seat__buy_btn" onClick={() => next()}>
              BUY {selectedSeats.length > 0 ? selectedSeats.length : null}{" "}
              TICKET(s)
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlatinumLeft;
