import { Link } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import styles from "./index.module.css";

const Hero = () => {
  // const { t } = useTranslation();
  return (
    <div className={styles.early__bird_home}>
      <Link
        to={`collection/${process.env.REACT_APP_HERO_1}`}
        // to={`explorer`}
        className={styles.early__bird_item}
      >
        {/* <img
          className={styles.early__bird_img}
          alt="chile"
          src="/img/hero/nightday.jpg"
        /> */}
        <video
          autoPlay
          playsInline
          loop
          controls
          muted
          className={styles.early__bird_video}
          id="video"
        >
          <source src="/video/Troya.MP4" type="video/mp4" />
          Your browser does not support thte video tag.
        </video>
        <div className={styles.early__bird_bottom}>
          <p className={styles.early__bird_text}>
            <span className={styles.early__bird_date}>
              Usa Tour Tickets Early Bird Sale start on November 7-8th 2025
            </span>
          </p>
        </div>
      </Link>
      <Link
        // to={`/collection/${process.env.REACT_APP_HERO_2}`}
        to={`#`}
        className={styles.early__bird_item}
      >
        {/* <img
          className={styles.early__bird_img}
          alt="TCF_RIFA"
          src="/img/hero/TCF_RIFA.png"
        /> */}
        <video
          autoPlay
          playsInline
          loop
          controls
          muted
          className={styles.early__bird_video}
          id="video"
        >
          <source src="/video/Life3.mp4" type="video/mp4" />
          Your browser does not support thte video tag.
        </video>
        <div className={styles.early__bird_bottom}>
          <p className={styles.early__bird_text}>
            <span className={styles.early__bird_date}>
              Burak Yeter 2025 Live Tour ticket coming soon
            </span>
          </p>
        </div>
      </Link>
    </div>
  );
};

export default Hero;
